
import {Controller} from "stimulus";

export default class extends Controller {

  interval = null;

  connect() {
    const root = this.element;
    this.status = root.dataset.uploadStatus;
    this.url = document.location.toString();
    if (this.status !== 'complete') {
      this.checkStatus();
    }
  }

  checkStatus() {
    fetch(this.url + '.json')
      .then(response => response.json())
      .then(json => {
        const { completed } = json;

        if (completed) {
          fetch(this.url + '.html', {
            headers: {
              "X-Requested-With": "XMLHttpRequest"
            }
          })
            .then(response => response.text())
            .then(html => {
              this.element.parentNode.innerHTML = html;
            })
        } else {
          this.interval = window.setTimeout(() => this.checkStatus(), 2000);
        }
    });
  }
}
