import {Controller} from "stimulus";
import {DirectUpload} from "activestorage";

export default class extends Controller {

  uploadFile = (file, url, name) => {
    const upload = new DirectUpload(file, url, this);

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        const root = this.element;
        // root.querySelector('.btn').style.display = "block";
        root.querySelector('.note').innerHTML =  "Upload complete!";

        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = name;
        this.element.querySelector('form').appendChild(hiddenField);
        this.element.querySelector('form').submit()
      }
    });
  };

  connect() {
    const root = this.element;
    root.droppable = true;
    const fileInputField = root.querySelector('input[type="file"]');
    const url = fileInputField.dataset.directUploadUrl;
    const name = fileInputField.name;

    const upload = (file) => {
      root.querySelector('.note').innerHTML =  "Uploading file...";
      this.uploadFile(file, url, name);
    }

    fileInputField.addEventListener('change', () => {
      const file = fileInputField.files[0];
      upload(file);
      fileInputField.value = null;
    }, false);
    root.querySelector('.note').addEventListener('click',
      () => fileInputField.click(), false )

    root.addEventListener('dragover', (event) => {
      event.preventDefault()
      event.stopPropagation()
    }, false)
    root.addEventListener('drop',
      (event) => {
        event.preventDefault()
        event.stopPropagation()
        upload(event.dataTransfer.files[0]);
    }, false)
  }

  disconnect() {
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    console.log('upload progress made')
    const root = this.element;
    root.querySelector(".progress").style.display = "block";
    root.querySelector('.progress .progress-bar').style.width = `${event.loaded * 100 / event.total}%`;
  }

}
