/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require.context('../images', true)

$ = window.$
jQuery = window.$

import Rails from 'rails-ujs';
Rails.start();

import "controllers"

$(document).ready(function() {
   window.toastr.options.progressBar = true;
   window.toastr.options.positionClass = 'toast-bottom-right';
   $('[data-toggle="tooltip"]').tooltip();
   $(document).on('show.bs.collapse', '[data-remote-url]', function() {
      const $el = $(this)
      if (!$el.attr('data-loaded')) {
         const url = $(this).attr('data-remote-url')
         $el.attr('data-loaded', true)
         $(this).load(url, function() {
            $('[data-toggle="tooltip"]').tooltip();
         })
      }
   })
});


import "pace-progress"
import "perfect-scrollbar"
import "@coreui/coreui-pro"

import "@fancyapps/fancybox"
import "@fancyapps/fancybox/dist/jquery.fancybox.css"

// Styles
import '@coreui/icons/css/coreui-icons.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';

// Import Main styles for this application
import '../stylesheets/application.scss'

